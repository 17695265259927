import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { AreaIcon } from "../../components/Icon/AreaIcon";
import { CalendarIcon } from "../../components/Icon/CalendarIcon";
import { FireIcon } from "../../components/Icon/FireIcon";
import { MapPreview } from "../../components/MapPreview";
import { Loader } from "../../components/UI";
import { HazardDetailCard } from "../../containers/Hazards/HazardDetailCard";
import { I18n } from "../../i18n";
import { Card } from "../../layouts/Card";
import { Page } from "../../layouts/Page";
import { useFetchHazardDetailsQuery } from "../../redux-rtk";
import {
  AppActions,
  LocationSelectionType,
} from "../../redux-rtk/slices/appSlice";

export const HazardDetails = () => {
  const dispatch = useDispatch();

  const { id: hazardId } = useParams();

  const { data: hazard, isLoading } = useFetchHazardDetailsQuery(hazardId);

  useEffect(() => {
    if (hazard) {
      dispatch(
        AppActions.selectLocation({
          id: hazard.id,
          details: hazard,
          type: LocationSelectionType.Hazard,
          position: [hazard.latitude, hazard.longitude],
        })
      );
    }
  }, [hazard]);

  return (
    <Page hasBackButton onBackPressNavigateTo="/hazards">
      {isLoading || !hazard ? (
        <Loader />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Typography variant="h1" sx={theme => ({ ...theme.typography.h2 })}>{hazard.name}</Typography>

          <Typography variant="caption">
            {I18n.t("fireDetails.updated")}:{" "}
            {dayjs(hazard.updatedDate).format("hh:mma MM/DD/YYYY")}
          </Typography>

          <HazardDetailCard
            icon={<FireIcon role="img" aria-label="" />}
            title={I18n.t("fireDetails.containmentPercentage")}
            value={
              hazard.percentContained ? `${hazard.percentContained}%` : "N/A"
            }
          />

          <HazardDetailCard
            icon={<CalendarIcon role="img" aria-label="" />}
            title={I18n.t("fireDetails.startDate")}
            value={dayjs(hazard.startedDate).format("MM/DD/YYYY")}
          />

          <HazardDetailCard
            icon={<AreaIcon role="img" aria-label="" />}
            title={I18n.t("fireDetails.acreage")}
            value={hazard.acreage.toLocaleString()}
          />

          {hazard.affectedCounties?.length > 0 && (
            <HazardDetailCard
              icon={<AreaIcon role="img" aria-label="" />}
              title={I18n.t("fireDetails.countiesAffected")}
            >
              <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
                {hazard.affectedCounties?.map((countyName) => (
                  <Card
                    padding={1.5}
                    key={countyName}
                    hasShadow={false}
                    sx={{ display: "flex", gap: 2 }}
                  >
                    {`${countyName}, ${hazard.state}`}
                  </Card>
                ))}
              </Box>
            </HazardDetailCard>
          )}

          <MapPreview />
        </Box>
      )}
    </Page>
  );
};
