/* eslint-disable complexity */
import { Box } from '@mui/material';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useCallback } from 'react';
import RoadClosureIcon from '../../../assets/icons/road-closures.png';
import TrafficControlPointIcon from '../../../assets/icons/traffic-control-points.png';
import { I18n } from '../../../i18n';
import { Card } from '../../../layouts/Card';
import {
  useAppDispatch,
  useFetchZoneEvacuationPointsQuery,
  useFetchZoneTrafficControlPointsQuery,
} from '../../../redux-rtk';
import {
  AppActions,
  LocationSelectionType,
} from '../../../redux-rtk/slices/appSlice';
import { Divider } from '../../Divider';
import { Transition } from '../../Transition';
import { Loader } from '../../UI';
import { Icon } from '../../UI/Icon';
import { Title } from '../shared/Title';
import { PointOfInterest } from './PointOfInterest';

interface Props {
  zoneId: string;
}

export const PointsOfInterestContainer = ({ zoneId }: Props) => {
  const dispatch = useAppDispatch();
  const { data: evacuationPointsRes, isLoading: isLoadingEvacuationPoints } =
    useFetchZoneEvacuationPointsQuery(zoneId, {
      refetchOnMountOrArgChange: true,
    });
  const {
    data: trafficControlPointsRes,
    isLoading: isLoadingTrafficControlPoints,
  } = useFetchZoneTrafficControlPointsQuery(zoneId, {
    refetchOnMountOrArgChange: true,
  });

  const handleOnViewPointOfInterest = useCallback(
    (pointOfInterest, type: LocationSelectionType) => {
      const { latitude, longitude, id, name, address, tcpNote } =
        pointOfInterest;

      const details =
        type === LocationSelectionType.EvacuationCenter
          ? {
              id,
              name,
              type,
              address,
            }
          : {
              id,
              address: name,
              type,
              description: tcpNote ? stateToHTML(convertFromRaw(tcpNote)) : '',
            };

      return dispatch(
        AppActions.selectLocation({
          id,
          type,
          details,
          position: [latitude, longitude],
        }),
      );
    },
    [dispatch],
  );
  const trafficControlPoints =
    trafficControlPointsRes?.trafficControlPoints || [];
  const evacuationPoints = evacuationPointsRes?.arrivalPoints || [];

  const hideComponent =
    !isLoadingEvacuationPoints &&
    !isLoadingTrafficControlPoints &&
    evacuationPoints.length === 0 &&
    trafficControlPoints.length === 0;

  return (
    <Transition in={!hideComponent} mountOnEnter>
      <Card hasShadow={false} backgroundColor="grey">
        {(evacuationPoints.length > 0 || isLoadingEvacuationPoints) && (
          <>
            <Title
              title={I18n.t('zoneDetails.evacuationPoints')}
              icon={<Icon size={18} name={'evacuation-points'} isDecorative={true} />}
            />

            <Box
              sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              {isLoadingEvacuationPoints ? (
                <Loader />
              ) : evacuationPoints.length === 0 ? (
                <div className="no-pois-container">
                  <div className="no-pois-text">
                    {I18n.t('zoneDetails.noEvacuationPoints')}
                  </div>
                </div>
              ) : (
                evacuationPoints?.map((el, index) => (
                  <PointOfInterest
                    key={index}
                    title={el.name}
                    description={el.address}
                    onView={() =>
                      handleOnViewPointOfInterest(
                        el,
                        LocationSelectionType.EvacuationCenter,
                      )
                    }
                  />
                ))
              )}
            </Box>
          </>
        )}

        {(trafficControlPoints.length > 0 || isLoadingTrafficControlPoints) && (
          <>
            {(evacuationPoints.length > 0 || isLoadingEvacuationPoints) && (
              <Divider sx={{ my: 3 }} />
            )}

            <Title
              title={I18n.t('zoneDetails.trafficControlPoints')}
              icon={<Icon size={20} name={'road-closures'} />}
            />

            <Box
              sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              {isLoadingTrafficControlPoints ? (
                <Loader />
              ) : trafficControlPoints.length === 0 ? (
                <div className="no-pois-container">
                  <div className="no-pois-text">
                    {I18n.t('zoneDetails.noTrafficControlPoints')}
                  </div>
                </div>
              ) : (
                trafficControlPoints?.map((el, index) => (
                  <PointOfInterest
                    key={index}
                    title={el.name}
                    description={el.address}
                    iconSrc={
                      el.type === LocationSelectionType.TrafficControlPoint
                        ? TrafficControlPointIcon
                        : RoadClosureIcon
                    }
                    onView={() => handleOnViewPointOfInterest(el, el.type)}
                  />
                ))
              )}
            </Box>
          </>
        )}
      </Card>
    </Transition>
  );
};
