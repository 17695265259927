import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { I18n } from 'i18n';
import { Card } from 'layouts/Card';
import { PropsWithSx } from 'utils';
import { PrimaryButton } from '~/components/common/Buttons';
import { AlarmBellIcon } from '~/components/Icon/AlarmBellIcon';
import { H3Styled } from '../common/Styled/H3Styled';

export const CriticalNotificationsBanner = ({ sx }: PropsWithSx<{}>) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        padding: 0,
        width: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 1,
        ...sx,
      }}
    >
      <Card
        backgroundColor="grey"
        hasShadow={false}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <AlarmBellIcon
          sx={{ overflow: 'visible', height: 20, width: 20, mr: 3, mb: 1 }}
          role="img"
          aria-label=""
        />
        <H3Styled variant="h1" sx={{ position: 'relative', top: 2 }}>
          {I18n.t('marketing.criticalNotifications')}
        </H3Styled>
      </Card>

      <Box sx={{ p: 2, minHeight: 100, width: '100%' }}>
        <Typography variant="body1">
          {I18n.t('marketing.criticalNotificationsDescription', { appName: I18n.t("common.appName") })}
        </Typography>

        <PrimaryButton
          label={I18n.t('marketing.downloadAppNow')}
          onClick={() =>
            navigate("/download")
          }
          sx={{
            mt: 2,
            width: '100%',
            bgcolor: 'secondary.pale',
            '&:hover': { bgcolor: 'secondary.dark' },
          }}
        />
      </Box>
    </Card>
  );
};
