import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import { Button, Typography } from "@mui/material";

import { I18n } from "../../../i18n";
import { PropsWithSx } from "../../../utils";

interface BackButtonProps {
  label?: string;
  onClick: () => void;
}

export const BackButton = ({
  sx,
  onClick,
  label = I18n.t("common.back"),
}: PropsWithSx<BackButtonProps>) => {
  return (
    <Button
      onClick={onClick}
      startIcon={
        <KeyboardArrowLeftRoundedIcon
          sx={{ mx: 0, position: "relative", right: -4, bottom: 0.5 }}
          role="img"
          aria-label=""
        />
      }
      size="medium"
      sx={{
        display: "flex",
        width: "fit-content",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50px",
        color: "secondary.main",
        backgroundColor: "grey.50",
        '&.Mui-focusVisible': {
          backgroundColor: "grey.25",
          outline: "2px solid #101010",
        },
        ...sx,
      }}
    >
      <Typography variant="body2" sx={{ mr: 1 }}>
        {label}
      </Typography>
    </Button>
  );
};
