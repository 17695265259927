import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIsMobileDevice } from 'hooks';
import { I18n } from 'i18n';
import BigPicure from '~/assets/images/app-visual-01.png'
import { ReactComponent as AppleStoreDownloadImage } from '~/assets/images/donwload-app-appstore.svg';
import { ReactComponent as GooglePlayDownloadimage } from '~/assets/images/donwload-app-googleplay.svg';
import { ReactComponent as AppLogo } from '~/assets/images/logo/logo_protect_with_text_horizontal_black.svg';
import { DownloadAppQRModal, DownloadAppVariant } from '~/components/Modals/DownloadAppQRModal';
import { Page } from "~/layouts/Page";

const BlueCircleAdditionalWidth = 200;

const downloadButtonStyles: CSSProperties = {
  background: "none",
  border: "none",
 };

export const DownloadApp = () => {
  const isMobile = useIsMobileDevice();
  const navigate = useNavigate();
  const theme = useTheme();
  const [modalVariant, setModalVariant] = useState<DownloadAppVariant | undefined>()


  const donwloadClickHandler = (downloadVariant: DownloadAppVariant) => {
    if(!isMobile) {
      setModalVariant(downloadVariant);
    } else {
      if(downloadVariant === "appstore") {
        window.location.href = "http://genasys.com/get-gp-ios";
      } else {
        window.location.href = "http://genasys.com/get-gp-android";
      }
    }
  }
  return (
    <Page>
      <Box
        sx={{
          width: "100%",
        }}
      >
      <Box
        sx={{
          display: 'flex',
          position: "relative",
          flexDirection: 'column',
          justifyContent: "center",
          flexWrap: "wrap",
          height: "auto",
          paddingLeft: "3%",
          width: ['80%', '80%', '60%'],
          zIndex: 1
        }}>
          <AppLogo
            style={{
              paddingBottom: theme.spacing(3),
              height: "auto",
              width: "100%",
              [theme.breakpoints.down("md")]: {
                width: '100%'
            }
            }} data-testid="app-logo" title={`${I18n.t("common.appName")} Logo`} />
          <Typography
            variant={isMobile ? 'h2' : 'h1'}
            sx={{
              width: "100%",
              pb: 1,
              lineHeight: 1.25,
              color: 'primary.main',
              textShadow: "0 0 5px white"
            }}
          >
            {I18n.t('downloadApp.title')}
          </Typography>

          <Typography
            sx={{
              width: "100%",
              pb: 4,
              fontSize: 25,
              lineHeight: 1.25,
              letterSpacing: -1,
              textTransform: "unset",
              color: "#54596D",
              textShadow: "0 0 5px white"
            }}
          >
            {I18n.t('downloadApp.mainText')}
          </Typography>

          <Typography
            variant='h2'
            sx={{
              width: "100%",
              pb: 2,
              letterSpacing: -1,
              textTransform: "unset",
              color: "#54596D",
              textShadow: "0 0 5px white"
            }}
          >
            {I18n.t('downloadApp.clickToDownload')}
          </Typography>
          <Grid container sx={{ gap: 2 }}>
            <Grid item sx={{ cursor: "pointer" }}>
              <button onClick={()=> donwloadClickHandler("appstore")} style={downloadButtonStyles} title="Download on App Store">
                <AppleStoreDownloadImage role="img" aria-label="Download on App Store"/>
              </button>
            </Grid>
            <Grid item sx={{ cursor: "pointer" }}>
              <button onClick={()=> donwloadClickHandler("googleplay")} style={downloadButtonStyles} title="Download in Google Play">
                <GooglePlayDownloadimage role="img" aria-label="Download in Google Play"/>
              </button>
            </Grid>
          </Grid>
          {modalVariant && <DownloadAppQRModal variant={modalVariant} isOpen={!!modalVariant} onClose={ ()=> setModalVariant(undefined) }/>}
        </Box>
        <Box
          component="img"
          src={BigPicure}
          alt="logo"
          draggable={false}
          sx={{
            width: "100%",
            [theme.breakpoints.up("lg")]: {
              width: "auto",
              position: 'absolute',
              height: "85%",
              bottom:0,
              right: 0,
              zIndex: 0,
            },
          }}
      />
      </Box>
  </Page>);
};