import { Error, ThumbUpAltRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { I18n } from '~/i18n';
import { Card } from '~/layouts/Card';

interface Props {
  isAccurate: boolean;
}

export const AccuracyCard = ({ isAccurate }: Props) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {!isAccurate ? (
        <Error sx={{ color: 'danger' }} />
      ) : (
        <ThumbUpAltRounded sx={{ color: 'primary.light' }} role="img" aria-label="" />
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h4">
          {I18n.t(
            `search.${
              !isAccurate ? 'lowBrowserAccuracy' : 'highBrowserAccuracy'
            }`,
          )}
        </Typography>

        <Typography variant="body2">
          {I18n.t(
            `search.${
              !isAccurate
                ? 'lowBrowserAccuracyDescription'
                : 'highBrowserAccuracyDescription'
            }`,
          )}
        </Typography>
      </Box>
    </Card>
  );
};
