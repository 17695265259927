import { Typography } from "@mui/material";
import { useCallback, useContext } from "react";
import { useLocalStorage } from "usehooks-ts";

import { ReactComponent as NoLocationFoundIcon } from "assets/icons/no-location-found.svg";

import { ModalsContext, ModalsContextIds } from "../../context";
import { I18n } from "../../i18n";
import { Card } from "../../layouts/Card";
import { resetUserLocationPreferences, useAppDispatch } from "../../redux-rtk";
import { LocalStorageKeys, LocationOrigin } from "../../types";
import { PrimaryButton } from "../common/Buttons/PrimaryButton";

export const GPSErrorCard = () => {
  const dispatch = useAppDispatch();
  const contextValue = useContext(ModalsContext);

  const [, setPrefferedOrigin] = useLocalStorage<LocationOrigin>(
    LocalStorageKeys.PreferredOrigin,
    LocationOrigin.Pending
  );

  const handleAllow = useCallback(() => {
    setPrefferedOrigin(LocationOrigin.Browser);
    dispatch(resetUserLocationPreferences());
    contextValue.setCurrentOpenedModal(ModalsContextIds.GpsPermission);
  }, []);

  return (
    <Card>
      <Typography
        variant="h3"
        sx={{
          gap: 1.5,
          display: "flex",
          alignItems: "center",
        }}
      >
        <NoLocationFoundIcon width="36px" height="36px" />
        {I18n.t("common.somethingWentWrong")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {I18n.t("search.errorWhileFetchingBrowsersLocation")}
      </Typography>

      <PrimaryButton
        onClick={handleAllow}
        sx={{ width: "100%", mt: 2 }}
        label={I18n.t("search.shareLocation")}
      />
    </Card>
  );
};
