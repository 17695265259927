export const layersConfig = {
  minZoomLevel: {
    evacuationZone: 7,
    evacuationZoneId: 10,
    countyName: 7,
    countyBoundary: 7,
    countySeal: 7,
    fire: 7,
  },
  maxZoomLevel: {
    evacuationZone: 22,
    evacuationZoneId: 22,
    countyName: 24,
    countyBoundary: 22,
    fire: 24,
  },
};
